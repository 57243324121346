import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '../../api';


function* loadGroups({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setGroups({ isGroupsFetching: true }));
    const data = yield call(() => api.get(`admin/groups`));

    yield put(actions.setGroups(
      {
        groups: data,
        isGroupsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setGroups({ error: error, isGroupsFetching: false }));
  }
}


export default function* groupsSaga() {
  yield all([
    takeEvery(actions.LOAD_GROUPS, loadGroups)
  ]);
}
