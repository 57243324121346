const actions = {

  // Polls

  LOAD_POLLS: 'LOAD_POLLS',
  SEARCH_POLLS: 'SEARCH_POLLS',
  DELETE_POLLS: 'DELETE_POLLS',
  SET_POLLS: 'SET_POLLS',

  // Polls Actions

  fetchPolls: (params = {}) => ({ type: actions.LOAD_POLLS, payload: params }),
  searchPolls: (params = {}) => ({ type: actions.SEARCH_POLLS, payload: params }),
  deletePolls: (params = {}) => ({ type: actions.DELETE_POLLS, payload: params }),

  setPolls: payload => ({
    type: actions.SET_POLLS,
    payload,
  }),


  // Polls

  LOAD_POLL: 'LOAD_POLL',
  ADD_POLL: 'ADD_POLL',
  UPDATE_POLL: 'UPDATE_POLL',
  DELETE_POLL: 'DELETE_POLL',
  EXPORT_POLL: 'EXPORT_POLL',
  SET_POLL: 'SET_POLL',

  // Polls Actions

  fetchPoll: (params = {}) => ({ type: actions.LOAD_POLL, payload: params }),
  addPoll: (poll = {}) => ({ type: actions.ADD_POLL, payload: poll }),
  updatePoll: (id, poll = {}) => ({ type: actions.UPDATE_POLL, payload: {id, poll} }),
  deletePoll: (id) => ({ type: actions.DELETE_POLL, payload: { id } }),
  exportPoll: (id) => ({ type: actions.EXPORT_POLL, payload: { id } }),

  setPoll: payload => ({
    type: actions.SET_POLL,
    payload,
  }),


  CLEAR_DATA: 'CLEAR_DATA',

  clearData: () => ({
    type: actions.CLEAR_DATA,
  }),

};
export default actions;
