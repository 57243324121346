import moment from 'moment';

export const mock = {
  users: {
    status: "success",
    pagination: {
      page: 1,
      total_pages: 3,
      page_size: 2,
      total_items: 6,
    },
    data: [
      {
        id: "R1",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000000",
        mobile_app_auth: true,
        has_notes: true,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
      {
        id: "R2",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000001",
        mobile_app_auth: true,
        has_notes: false,
        groups: [
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
      {
        id: "R3",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
      {
        id: "R4",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
      {
        id: "R5",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
      {
        id: "R6",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
    ],
  },
  users_search: [
      {
        id: "R1",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000000",
        mobile_app_auth: true,
        has_notes: true,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
      {
        id: "R2",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000001",
        mobile_app_auth: true,
        has_notes: false,
        groups: [
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
      {
        id: "R3",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
      {
        id: "R4",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000002",
        mobile_app_auth: false,
        has_notes: false,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          }
        ]
      },
    ],
  users_search_full_name: [
      {
        id: "R1",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000000",
        mobile_app_auth: true,
        has_notes: true,
        groups: [
          {
            title: 'Гипертония',
            sub: [
              '2 степень'
            ]
          },
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
      {
        id: "R2",
        last_name: "Last name",
        first_name: "First name",
        patronymic: "Patronymic name",
        phone: "79990000001",
        mobile_app_auth: true,
        has_notes: false,
        groups: [
          {
            title: 'Не курит',
            sub: []
          },
          {
            title: 'Астма',
            sub: [
              'пневомония',
              'бронхит'
            ]
          }
        ]
      },
    ],
  discounts:  [
    {
      id: 1,
      title: 'Тест акция',
      created_at: '02.02.2020',
    },
    {
      id: 2,
      title: 'Тест акция 1',
      created_at: '02.02.2020',
    },
    {
      id: 3,
      title: 'Тест акция 2',
      created_at: '02.02.2020',
    },
    {
      id: 4,
      title: 'Тест акция 3',
      created_at: '02.02.2020',
    },
    {
      id: 5,
      title: 'Тест акция 4',
      created_at: '02.02.2020',
    },
  ],
  news: [
    {
      id: 1,
      title: '1 "Подготовка к наступлению": эксперт оценил силы НАТО у границ России',
      text: 'МОСКВА, 17 мая — РИА Новости. Военный эксперт Иван Коновалов в разговоре с RT оценил учения НАТО в Эстонии.\n' +
        'Как сообщал главный штаб эстонских сил обороны, учения "Весенний шторм" пройдут с 17 мая по 5 июня, в них примут участие почти семь тысяч военнослужащих из нескольких стран НАТО.\n' +
        '"Натовские подразделения находятся у российских границ, что является прямым нарушением Основополагающего акта о взаимных отношениях, сотрудничестве и безопасности между Российской Федерацией и блоком от 1997 года. В документе, в частности, говорится о том, что стороны будут стремиться к транспарентности, предсказуемости и взаимному доверию в отношении размеров и функций военных сил", — сказал Коновалов.\n' +
        'Он отметил, что в действительности войска альянса постоянно находятся у российских границ, а ударная составляющая учений, особенно в Восточной Европе, регулярно возрастает.',
      image_url: 'https://cdn25.img.ria.ru/images/07e5/04/1c/1730384726_0:65:3072:1793_640x0_80_0_0_60cac59c118e7fd135ca03abcd1ec190.jpg.webp',
      created_at: '02.02.2020 14:00:00',
      published_at: '',
      external_url: 'https://lenta.ru/articles/2021/05/11/mate/',
      status: 'draft',
    },
    {
      id: 2,
      title: '2 "Подготовка к наступлению": эксперт оценил силы НАТО у границ России',
      text: 'МОСКВА, 17 мая — РИА Новости. Военный эксперт Иван Коновалов в разговоре с RT оценил учения НАТО в Эстонии.\n' +
        'Как сообщал главный штаб эстонских сил обороны, учения "Весенний шторм" пройдут с 17 мая по 5 июня, в них примут участие почти семь тысяч военнослужащих из нескольких стран НАТО.\n' +
        '"Натовские подразделения находятся у российских границ, что является прямым нарушением Основополагающего акта о взаимных отношениях, сотрудничестве и безопасности между Российской Федерацией и блоком от 1997 года. В документе, в частности, говорится о том, что стороны будут стремиться к транспарентности, предсказуемости и взаимному доверию в отношении размеров и функций военных сил", — сказал Коновалов.\n' +
        'Он отметил, что в действительности войска альянса постоянно находятся у российских границ, а ударная составляющая учений, особенно в Восточной Европе, регулярно возрастает.',
      image_url: 'https://cdn25.img.ria.ru/images/07e5/04/1c/1730384726_0:65:3072:1793_640x0_80_0_0_60cac59c118e7fd135ca03abcd1ec190.jpg.webp',
      created_at: '02.03.2020 14:00:00',
      published_at: '02.03.2020 14:30:00',
      external_url: 'https://lenta.ru/articles/2021/05/11/mate/',
      status: 'published',
    },
    {
      id: 3,
      title: '3 "Подготовка к наступлению": эксперт оценил силы НАТО у границ России',
      text: 'МОСКВА, 17 мая — РИА Новости. Военный эксперт Иван Коновалов в разговоре с RT оценил учения НАТО в Эстонии.\n' +
        'Как сообщал главный штаб эстонских сил обороны, учения "Весенний шторм" пройдут с 17 мая по 5 июня, в них примут участие почти семь тысяч военнослужащих из нескольких стран НАТО.\n' +
        '"Натовские подразделения находятся у российских границ, что является прямым нарушением Основополагающего акта о взаимных отношениях, сотрудничестве и безопасности между Российской Федерацией и блоком от 1997 года. В документе, в частности, говорится о том, что стороны будут стремиться к транспарентности, предсказуемости и взаимному доверию в отношении размеров и функций военных сил", — сказал Коновалов.\n' +
        'Он отметил, что в действительности войска альянса постоянно находятся у российских границ, а ударная составляющая учений, особенно в Восточной Европе, регулярно возрастает.',
      image_url: 'https://cdn25.img.ria.ru/images/07e5/04/1c/1730384726_0:65:3072:1793_640x0_80_0_0_60cac59c118e7fd135ca03abcd1ec190.jpg.webp',
      created_at: '02.04.2020 14:00:00',
      published_at: '02.04.2020 14:30:00',
      external_url: 'https://lenta.ru/articles/2021/05/11/mate/',
      status: 'published',
    },
    {
      id: 4,
      title: '4 "Подготовка к наступлению": эксперт оценил силы НАТО у границ России',
      text: 'МОСКВА, 17 мая — РИА Новости. Военный эксперт Иван Коновалов в разговоре с RT оценил учения НАТО в Эстонии.\n' +
        'Как сообщал главный штаб эстонских сил обороны, учения "Весенний шторм" пройдут с 17 мая по 5 июня, в них примут участие почти семь тысяч военнослужащих из нескольких стран НАТО.\n' +
        '"Натовские подразделения находятся у российских границ, что является прямым нарушением Основополагающего акта о взаимных отношениях, сотрудничестве и безопасности между Российской Федерацией и блоком от 1997 года. В документе, в частности, говорится о том, что стороны будут стремиться к транспарентности, предсказуемости и взаимному доверию в отношении размеров и функций военных сил", — сказал Коновалов.\n' +
        'Он отметил, что в действительности войска альянса постоянно находятся у российских границ, а ударная составляющая учений, особенно в Восточной Европе, регулярно возрастает.',
      image_url: 'https://cdn25.img.ria.ru/images/07e5/04/1c/1730384726_0:65:3072:1793_640x0_80_0_0_60cac59c118e7fd135ca03abcd1ec190.jpg.webp',
      created_at: '02.05.2020 14:00:00',
      published_at: '',
      external_url: 'https://lenta.ru/articles/2021/05/11/mate/',
      status: 'draft',
    },
  ],
  polls: [
    {
      id: 1,
      title: 'Наименование опроса',
      stated_at: '02.01.2020 14:38:48',
      ended_at: '04.01.2020 14:38:48',
      range_date: [],
      total_respondents: 100,
      total_responding: 75,
      groups: [
        {
          'title': 'Курит',
          'sub': [
            'бросил'
          ]
        },
        {
          'title': 'Не курит',
          'sub': []
        }
      ],
      status: 'draft',
      description: 'Описание опроса',

      questions: [
        {
          "type": "radiogroup",
          "code": "Q124",
          "title": "Are you 18 years old or older?",
          "response_variants": [
            {
              "code": "Yes",
              "text": "yes"
            },
            {
              "code": "No",
              "text": "no"
            }
          ]
        },
        {
          "type": "text",
          "code": "Q128",
          "title": "Текстовый вопрос",
          //"inputType": "number" // нужно ли больше типов давать выбирать руками?
        },
        {
          "type": "checkbox",
          "title": "Вопрос с множественным выбором",
          "code": "Q129",
          "response_variants": [
            {
              "code": "11111",
              "text": "11111"
            },
            {
              "code": "22222",
              "text": "22222"
            }
          ]
        },
        {
          "type": "slider",
          "title": "Вопрос с рейтингом",
          "code": "Q130",
          "low_bound_name": "low_bound_name",
          "high_bound_name": "high_bound_name",
          "count_points": 2,
        }
      ]
    },
    {
      id: 2,
      title: 'Наименование опроса 1',
      stated_at: '02.02.2020 14:38:48',
      ended_at: '04.02.2020 14:38:48',
      total_respondents: 57,
      total_responding: 57,
      groups: [
        {
          'title': 'Курит',
          'sub': [
            'бросил'
          ]
        },
        {
          'title': 'Не курит',
          'sub': []
        }
      ],
      status: 'published',
      description: 'Описание опроса'
    },
    {
      id: 3,
      title: 'Наименование опроса 2',
      stated_at: '02.03.2020 14:38:48',
      ended_at: '04.03.2020 14:38:48',
      total_respondents: 4,
      total_responding: 1,
      groups: [
        {
          title: 'Гипертония',
          sub: [
            '2 степень'
          ]
        },
        {
          title: 'Не курит',
          sub: []
        },
        {
          title: 'Астма',
          sub: [
            'пневомония',
            'бронхит'
          ]
        }
      ],
      status: 'completed',
      description: 'Описание опроса'
    },
  ],
  groups: [
    {
      title: 'Группа',
      value: 'group',
      subgroups: [
        {
          title: 'Подгруппа 0 0',
          value: 'sub_group'
        },
        {
          title: 'Подгруппа 0 1',
          value: 'sub_group1'
        },
        {
          title: 'Подгруппа 0 2',
          value: 'sub_group2'
        },
      ]
    },
    {
      title: 'Группа 1',
      value: 'group1',
      subgroups: [
        {
          title: 'Подгруппа 1 0',
          value: 'sub_group'
        },
        {
          title: 'Подгруппа 1 1',
          value: 'sub_group1'
        },
        {
          title: 'Подгруппа 1 2',
          value: 'sub_group2'
        },
      ]
    },
    {
      title: 'Группа 2',
      value: 'group2',
      subgroups: [
        {
          title: 'Подгруппа 2 0',
          value: 'sub_group'
        },
        {
          title: 'Подгруппа 2 1',
          value: 'sub_group1'
        },
        {
          title: 'Подгруппа 2 2',
          value: 'sub_group2'
        },
      ]
    },
    {
      title: 'Группа 3',
      value: 'group3',
      subgroups: [
        {
          title: 'Подгруппа 3 0',
          value: 'sub_group'
        },
        {
          title: 'Подгруппа 3 1',
          value: 'sub_group1'
        },
        {
          title: 'Подгруппа 3 2',
          value: 'sub_group2'
        },
      ]
    },
  ],
  sub_groups: [
    {
      title: 'Подгруппа',
      value: 'sub_group'
    },
    {
      title: 'Подгруппа 1',
      value: 'sub_group1'
    },
    {
      title: 'Подгруппа 2',
      value: 'sub_group2'
    },
    {
      title: 'Подгруппа 3',
      value: 'sub_group3'
    },
    {
      title: 'Подгруппа 4',
      value: 'sub_group4'
    },
  ]
}