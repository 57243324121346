import update from 'immutability-helper';
import actions from './actions';

const initState = {
  groups: [],
  isGroupsFetching: false
};

export default function groups(state = initState, action) {
  switch (action.type) {
    case actions.SET_GROUPS:
      return update(state, {$merge: action.payload});

    default:
      return state;
  }
}
