import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';
import usersSaga from '@iso/redux/users/saga';
import pollsSaga from '@iso/redux/polls/saga';
import groupsSaga from '@iso/redux/groups/saga';
import discountsSaga from '@iso/redux/discounts/saga';
import newsSaga from '@iso/redux/news/saga';

export default function* rootSaga(getState) {
  yield all([authSaga(), usersSaga(), pollsSaga(), groupsSaga(), discountsSaga(), newsSaga()]);
}
