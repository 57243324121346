import { all, takeEvery, put, call } from 'redux-saga/effects';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from 'file-saver';

import actions from './actions';
import api from '@iso/api';
import notification from '@iso/components/utility/notification';
import { QUESTION_TYPES } from '@iso/config/common';



const formattedPolls = (poll, i) => {
  let groupIds = [];

  poll.groups.forEach(g => {
    if (g.parent) {
      groupIds = [
        ...groupIds,
        {
          group: g.parent.id,
          subgroup: g.id,
        }
      ]
    }

    if (g.subgroups && !g.subgroups.length && !g.parent) {
      groupIds = [
        ...groupIds,
        {
          group: g.id,
        }
      ]
    }
  });

  let questions = [];

  poll.questions.forEach((q, i) => {
    let newQuestion = q
    if (q.type === QUESTION_TYPES.checkbox || q.type === QUESTION_TYPES.radio) {
      newQuestion = {...q, response_variants: q.response_variants || []}
    }

    if (q.is_dependent) {
      // на фронте легче работать с айдишниками для создания зависимостей
      const depQ = poll.questions.find(fq => fq.name === q.depends_on_question);
      const depA = depQ.response_variants.find(fa => fa.name === q.depends_on_question_answer);
      newQuestion = {...newQuestion, depends_on_question: depQ.id, depends_on_question_answer: depA.id }
    }

    questions = [...questions, newQuestion]

  })

  return {
    ...poll,
    key: poll.id + i,
    group_ids: groupIds,
    is_dependent: false,
    questions: questions,
    started_at: poll.started_at && poll.started_at !== 'null' ?  moment.utc(poll.started_at, 'YYYY-MM-DDTHH:mm').tz('Europe/Moscow') : null,
    ended_at: poll.ended_at && poll.ended_at !== 'null' ?  moment.utc(poll.ended_at, 'YYYY-MM-DDTHH:mm').tz('Europe/Moscow')  : null
  }
}


const formattedRequestPolls = (poll) => {
  let group_ids = [];
  poll.group_ids.forEach(g => {
    if (g.subgroup) {
      group_ids = [...group_ids, g.subgroup]
    } else {
      if (g.group) {
        group_ids = [...group_ids, g.group]
      }
    }
  });

  let questions = [];

  poll.questions.forEach((q, i) => {
    let newQuestion = q
    if (q.type === QUESTION_TYPES.checkbox || q.type === QUESTION_TYPES.radio) {
      newQuestion = {...q, response_variants: q.response_variants || []}
    }

    if (q.is_dependent) {
      // на беке легче работать с кодами вопросов и ответов для создания зависимостей
      // поэтому заменяем айдишники на коды
      const depQ = poll.questions.find(fq => fq.id === q.depends_on_question);
      const depA = depQ.response_variants.find(fa => fa.id === q.depends_on_question_answer);
      newQuestion = {...newQuestion, depends_on_question: depQ.name, depends_on_question_answer: depA.name }
    }

    questions = [...questions, newQuestion]

  })

  return {
    ...poll,
    groups: group_ids,
    questions: questions,
    //started_at: poll.started_at ?  moment(poll.started_at).format() : null,
    ended_at: poll.ended_at ?  moment(poll.ended_at.set({'second': 59, 'minute': 59, 'hour': 23})).format() : null,
  }
}


function* loadPolls({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setPolls({ isPollsFetching: true, isPollDeleteSuccess: false, isPollAddingSuccess: false }));
    const data = yield call(() => api.get(`admin/quizzes`, params));

    yield put(actions.setPolls(
      {
        polls: data.map((p, i)=> (formattedPolls(p, i))),
        isPollsFetching: false
      }
    ));
  } catch (error) {
    console.log(error)
    yield put(actions.setPolls({ error: error, isPollsFetching: false }));
  }
}


function* loadPoll({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setPoll({ isPollFetching: true, isPollDeleteSuccess: false, isPollAddingSuccess: false }));
    const data = yield call(() => api.get(`admin/quizzes/${params.id}`));

    yield put(actions.setPoll({
        poll: formattedPolls(data),
        isPollFetching: false
    }));
  } catch (error) {
    yield put(actions.setPoll({ error: error, isPollFetching: false }));
  }
}

function* addPoll({ payload }) {
  try {
    const poll = { ...payload };
    yield put(actions.setPoll({ isPollAdding: true, isPollDeleteSuccess: false, isPollAddingSuccess: false }));
    const data = yield call(() => api.post(`admin/quizzes`, {}, formattedRequestPolls(poll)));

    yield put(actions.setPoll({
      //poll: poll,
      isPollAdding: false,
      isPollAddingSuccess: true,
    }));
  } catch (error) {
    yield put(actions.setPoll({ error: error, isPollAdding: false }));
  }
}

function* updatePoll({ payload }) {
  try {
    const { id, poll } = payload;
    yield put(actions.setPoll({ isPollUpdating: true, isPollDeleteSuccess: false }));
    const data = yield call(() => api.put(`admin/quizzes/${id}`, {}, formattedRequestPolls(poll)));

    notification('success', 'Опрос успешно обновлен');

    yield put(actions.setPoll({
      poll: poll,
      isPollUpdating: false
    }));
  } catch (error) {
    yield put(actions.setPoll({ error: error, isPollUpdating: false }));
  }
}


function* deletePoll({ payload }) {
  try {
    const { id } = payload;
    yield put(actions.setPoll({ isPollDeleting: true, isPollDeleteSuccess: false }));
    const data = yield call(() => api.delete(`admin/quizzes/${id}`));
    yield put(actions.setPoll({
      poll: null,
      isPollDeleting: false,
      isPollDeleteSuccess: true,
    }));
    notification('success', 'Опрос успешно удален');

  } catch (error) {
    yield put(actions.setPoll({ error: error, isPollUpdating: false, isPollDeleteSuccess: false }));
  }
}

function* exportPoll({ payload }) {
  try {
    const { id } = payload;
    yield put(actions.setPoll({ isPollDeleteSuccess: false }));
    const data = yield call(() => api.get(`admin/quizzes/${id}/results`, {}, {}, {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    }));
    var blob = new Blob([data], {type: "text/plain;charset=utf-8"});
    saveAs(blob, `report-${id}.xlsx`);
    notification('success', 'Результаты выгружены');
  } catch (error) {
    yield put(actions.setPoll({ error: error, isPollUpdating: false, isPollDeleteSuccess: false }));
  }
}



export default function* pollsSaga() {
  yield all([
    takeEvery(actions.LOAD_POLLS, loadPolls),

    takeEvery(actions.LOAD_POLL, loadPoll),
    takeEvery(actions.ADD_POLL, addPoll),
    takeEvery(actions.UPDATE_POLL, updatePoll),
    takeEvery(actions.DELETE_POLL, deletePoll),
    takeEvery(actions.EXPORT_POLL, exportPoll),
    //takeEvery(actions.SEARCH_POLLS_BY_PHONE, searchPollsByPhone),
  ]);
}
