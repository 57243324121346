const actions = {

  // Discounts

  LOAD_DISCOUNTS: 'LOAD_DISCOUNTS',
  LOAD_DISCOUNT: 'LOAD_DISCOUNT',
  SET_DISCOUNTS: 'SET_DISCOUNTS',
  ADD_DISCOUNT: 'ADD_DISCOUNT',
  EDIT_DISCOUNT: 'EDIT_DISCOUNT',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',

  // Discounts Actions

  fetchDiscounts: (params = {}) => ({ type: actions.LOAD_DISCOUNTS, payload: params }),

  fetchDiscount: (params = {}) => ({ type: actions.LOAD_DISCOUNT, payload: params }),

  setDiscounts: payload => ({
    type: actions.SET_DISCOUNTS,
    payload,
  }),

  addDiscount: payload => ({
    type: actions.ADD_DISCOUNT,
    payload,
  }),

  editDiscount: payload => ({
    type: actions.EDIT_DISCOUNT,
    payload,
  }),

  deleteDiscount: payload => ({
    type: actions.DELETE_DISCOUNT,
    payload,
  }),

  CLEAR_DATA: 'CLEAR_DATA',

  clearData: () => ({
    type: actions.CLEAR_DATA,
  }),

};
export default actions;
