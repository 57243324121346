const actions = {

  // Users

  LOAD_USERS: 'LOAD_USERS',
  SEARCH_USERS: 'SEARCH_USERS',
  SET_USERS: 'SET_USERS',
  EXPORT_USERS: 'EXPORT_USERS',
  SEND_PUSH_TO_USERS: 'SEND_PUSH_TO_USERS',
  SEND_SMS_TO_USERS: 'SEND_SMS_TO_USERS',
  LAST_SMS_SEND_DATE: 'LAST_SMS_SEND_DATE',

  // Users Actions

  fetchUsers: (params = {}) => ({ type: actions.LOAD_USERS, payload: params }),
  searchUsers: (params = {}) => ({ type: actions.SEARCH_USERS, payload: params }),
  exportUsers: (params = {}) => ({ type: actions.EXPORT_USERS, payload: params }),
  sendPushToUsers: (params = {}) => ({ type: actions.SEND_PUSH_TO_USERS, payload: params }),
  sendSmsToUsers: (params = {}) => ({ type: actions.SEND_SMS_TO_USERS, payload: params }),
  getLastSmsSendDate: (params = {}) => ({ type: actions.LAST_SMS_SEND_DATE, payload: params }),
  setUsers: payload => ({
    type: actions.SET_USERS,
    payload,
  }),

};
export default actions;
