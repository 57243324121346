const actions = {

  // Groups

  LOAD_GROUPS: 'LOAD_GROUPS',
  SET_GROUPS: 'SET_GROUPS',

  // Groups Actions

  fetchGroups: (params = {}) => ({ type: actions.LOAD_GROUPS, payload: params }),
  setGroups: payload => ({
    type: actions.SET_GROUPS,
    payload,
  }),

};
export default actions;
