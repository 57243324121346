import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '../../api'
import { mock } from '@iso/containers/mock';
import {openNotificationWithIcon} from "../../components/utility/utils";
import {NOTIFICATION_STATUSES} from "../../config/common";


function* loadNews({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setNews({ isNewsFetching: true, redirect: false }));
    const data = yield call(() => api.get(`admin/news`));
    yield put(actions.setNews(
      {
        news: data,
        isNewsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setNews({ error: error, isNewsFetching: false }));
  }
}

function* loadNewsDetail({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setNews({ isNewsFetching: true, redirect: false }));
    const data = yield call(() => api.get(`admin/news/${params.id}`));
    yield put(actions.setNews(
      {
        news_detail: data,
        isNewsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setNews({ error: error, isNewsFetching: false }));
  }
}

function* addNews({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setNews({ isNewsFetching: true, redirect: false }));
    const data = yield call(() => api.post(`admin/news`, {}, params));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Новость создана');
    yield put(actions.setNews(
      {
        news_detail: data,
        redirect: true,
        isNewsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setNews({ error: error, isNewsFetching: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* editNews({payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setNews({ isNewsFetching: true, redirect: false }));
    yield call(() => api.put(`admin/news/${params.id}`, {}, params));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Новость изменена');
    yield put(actions.setNews(
      {
        redirect: true,
        isNewsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setNews({ error: error, isNewsFetching: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* deleteNews({payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setNews({ isNewsFetching: true, redirect: false }));
    yield call(() => api.delete(`admin/news/${params.id}`));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Новость удалена');
    yield put(actions.setNews(
      {
        redirect: true,
        isNewsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setNews({ error: error, isNewsFetching: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

export default function* newsSaga() {
  yield all([
    takeEvery(actions.LOAD_NEWS, loadNews),
    takeEvery(actions.LOAD_NEWS_DETAIL, loadNewsDetail),
    takeEvery(actions.DELETE_NEWS, deleteNews),
    takeEvery(actions.ADD_NEWS, addNews),
    takeEvery(actions.EDIT_NEWS, editNews),
  ]);
}
