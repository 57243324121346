export const DEFAULT_OFFSET = 15;

export const DATETIME_FORMAT = "DD.MM.YYYY HH:mm:ss";

export const DATETIME_FORMAT_EXPORT = "DD-MM-YYYY-HH-mm-ss";

export const MAX_FILE_SIZE = 10;

export const DEFAULT_PAGE_SIZE = 20;

export const PAGE_SIZE_VARIANT = [DEFAULT_PAGE_SIZE, 50, 100];

export const QUESTION_TYPES = {
  radio: 'radio',
  text: 'text',
  checkbox: 'checkbox',
  slider: 'slider',
}

export const QUESTION_OPTIONS = [
  { label: 'Текст', value: 'text' },
  { label: 'Слайдер', value: 'slider' },
  { label: 'Один из нескольких вариантов', value: 'radio' },
  { label: 'Несколько из нескольких вариантов', value: 'checkbox' },
]

export const NEWS_STATUSES = {
  published: 'Опубликовано',
  draft: 'Черновик',
}

export const NEWS_STATUSES_OPTIONS = [
  { label: 'Опубликовано', value: 'PUBLISHED' },
  { label: 'Черновик', value: 'DRAFT' }
]

export const POLL_STATUSES = {
  published: 'Опубликован',
  completed: 'Завершен',
  draft: 'Черновик',
}

export const POLL_STATUSES_OPTIONS = [
  { label: 'Опубликован', value: 'published' },
  { label: 'Завершен', value: 'completed' },
  { label: 'Черновик', value: 'draft' }
]

export const NOTIFICATION_STATUSES = {
  success: 'success',
  error: 'error'
}

export const DEFAULT_FORM_SIZE = {
  labelCol: {
    span: 3
  },
  wrapperCol: {
    span: 8
  }
}

export const UPLOAD_IMAGE_FILE_PROPS = {
  action: `${process.env.REACT_APP_API_BASE_URL}file`,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('id_token')}`
  }
}

export const UPLOAD_USERS_FILE_PROPS = {
  action: `${process.env.REACT_APP_API_BASE_URL}admin/users/upload`,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('id_token')}`
  }
}