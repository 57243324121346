import update from 'immutability-helper';
import actions from './actions';

const initState = {
  news: [],
  news_detail: null,
  redirect: false,
  isNewsFetching: false
};

export default function news(state = initState, action) {
  switch (action.type) {
    case actions.SET_NEWS:
      return update(state, {$merge: action.payload});

    case actions.LOAD_NEWS:
      return update(state, {$merge: action.payload});

    case actions.LOAD_NEWS_DETAIL:
      return update(state, {$merge: action.payload});

    case actions.ADD_NEWS:
      return update(state, {$merge: action.payload});

    case actions.EDIT_NEWS:
      return update(state, {$merge: action.payload});

    case actions.DELETE_NEWS:
      return update(state, {$merge: action.payload});

    case actions.CLEAR_DATA:
      return update(state, {$set: {
          ...state,
          news_detail: null
        }});

    default:
      return state;
  }
}
