import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import api from "../../api";
import {openNotificationWithIcon} from "../../components/utility/utils";
import {NOTIFICATION_STATUSES} from "../../config/common";

const history = createBrowserHistory();

export function* loginRequest({ payload }) {
  try {
    const { values } = payload;
    const data = yield call(() => api.post(`admin/auth/signin`, {}, values, {'Content-Type': 'application/json', Accept: 'application/json'}));
    yield put({
      type: actions.LOGIN_SUCCESS,
      token: data.token,
      profile: 'Profile',
    });
  } catch (error) {
    yield put({ type: actions.LOGIN_ERROR });
    console.log(error);
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

export function* logout() {
  yield localStorage.removeItem('id_token');
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {
    yield localStorage.removeItem('id_token');
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN_REQUEST, loginRequest),
    takeEvery(actions.LOGOUT, logout),
    fork(checkAuthorization),
    fork(loginSuccess),
    fork(loginError),
  ]);
}
