import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Users from '@iso/redux/users/reducer';
import Polls from '@iso/redux/polls/reducer';
import Groups from '@iso/redux/groups/reducer';
import Discounts from '@iso/redux/discounts/reducer';
import News from '@iso/redux/news/reducer';

export default combineReducers({
  Auth,
  App,
  Users,
  Polls,
  Groups,
  Discounts,
  News,

  ThemeSwitcher,
  LanguageSwitcher,
});
