import update from 'immutability-helper';
import actions from './actions';

const initState = {
  discounts: [],
  discount: null,
  redirect: false,
  isDiscountsFetching: false
};

export default function discounts(state = initState, action) {
  switch (action.type) {
    case actions.SET_DISCOUNTS:
      return update(state, {$merge: action.payload});

    case actions.LOAD_DISCOUNTS:
      return update(state, {$merge: action.payload});

    case actions.LOAD_DISCOUNT:
      return update(state, {$merge: action.payload});

    case actions.ADD_DISCOUNT:
      return update(state, {$merge: action.payload});

    case actions.DELETE_DISCOUNT:
      return update(state, {$merge: action.payload});

    case actions.CLEAR_DATA:
      return update(state, {$set: {
          ...state,
          discount: null
        }});

    default:
      return state;
  }
}
