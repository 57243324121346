import {all, call, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import api from '../../api'
import {saveAs} from 'file-saver';
import {openNotificationWithIcon} from '@iso/components/utility/utils';
import {DATETIME_FORMAT_EXPORT, NOTIFICATION_STATUSES} from '@iso/config/common';
import moment from "moment";


function generateQuery(payload) {
  const search = [];

  if (payload.groups) {
    search.push(payload.groups)
  }

  if (payload.user_ids.length) {
    search.push(`(id=in=(${payload.user_ids.map(it => it)}))`)
  }

  if (payload.user_exclude_ids.length) {
    search.push(`(id=out=(${payload.user_exclude_ids.map(it => it)}))`)
  }

  if (payload.phone) {
    search.push(`(phone==*${payload.phone}*)`)
  }

  if (payload.full_name) {
    search.push(`(firstName==*${payload.full_name}*,lastName==*${payload.full_name}*,patronymic==*${payload.full_name}*)`)
  }

  return (search.length) ? {search: search.join(';')} : {};
}

function* loadUsers({ payload }) {
  try {
    yield put(actions.setUsers({ isUsersFetching: true }));
    let query = {};
    if (payload.page) {
      query['page'] = payload.page - 1;
    }
    if (payload.page_size) {
      query['size'] = payload.page_size;
    }
    const data = yield call(() => api.get(`admin/users`, query, {}, null, null, true));
    yield put(actions.setUsers(
      {
        users: data.data.map((u, i) => ({...u, key: u.id + i})),
        pageSize: data.pagination.page_size,
        page: data.pagination.page,
        totalElements: data.pagination.total_items,
        isUsersFetching: false,
      }
    ));
  } catch (error) {
    yield put(actions.setUsers({ error: error, isUsersFetching: false }));
  }
}

function* searchUsers({ payload }) {
  try {
    let query = '';
    const params = { ...payload };
    const phone = `phone==*${params.phone}*`;
    const full_name = `(firstName==*${params.full_name}*,lastName==*${params.full_name}*,patronymic==*${params.full_name}*)`;
    const page = `&page=${payload.page - 1}`;
    const size = `&size=${payload.size}`;
    yield put(actions.setUsers({ isUsersFetching: true }));

    if (params.phone) {
      query = `${phone}`;
    }
    if (params.full_name) {
      query = !!query ? `${query};${full_name}` : full_name;
    }
    if (params.search) {
      query = !!query ? `${query};${params.search}` : params.search;
    }
    if (params.page) {
      query += page;
    }
    if (params.size) {
      query += size;
    }

    const data = yield call(() => api.get(`admin/users?search=${query}`, {}, {}, null, null, true));
    yield put(actions.setUsers(
      {
        users: data.data.map((u, i) => ({...u, key: u.id + i})),
        pageSize: data.pagination.page_size,
        page: data.pagination.page,
        totalElements: data.pagination.total_items,
        isUsersFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setUsers({ error: error, isUsersFetching: false }));
  }
}

function* exportUsers({ payload }) {
  try {
    let url = null;
    switch (payload.type) {
      case 'users': {
        url = 'admin/users/export';
        break;
      }
      case 'injures': {
        url = 'admin/health_info/export';
        break;
      }
      case 'trauma': {
        url = 'admin/injury_info/export';
        break;
      }
    }
    if (!url) {
      openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', 'Не был выбран тип файла');
      return
    }
    const data = yield call(() => api.get(url, {}, {}, {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('id_token')
    }));
    let blob = new Blob([data]);
    saveAs(blob, `${payload.type}-${moment(new Date()).format(DATETIME_FORMAT_EXPORT)}.xlsx`);
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Результаты выгружены');
  } catch (error) {
    yield put(actions.setUsers({ error: error, isUsersFetching: false  }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* sendPushToUsers({ payload }) {
  try {
    yield put(actions.setUsers({ isSuccess: false }));
    let query = {};
    if (payload.user_ids.length < 50) {
      query = generateQuery(payload)
    }
    yield call(() => api.post(`admin/users/send_push`, query, {
      title: payload.title,
      body: payload.body,
      user_ids: ((!Object.keys(query).length) ? (payload.is_select_all ? null : payload.user_ids) : [])
    }));
    yield put(actions.setUsers({ isSuccess: true }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Push уведомления были отправлены');
  } catch (error) {
    yield put(actions.setUsers({ error: error, isSuccess: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* sendSmsToUsers({ payload }) {
  try {
    let query = generateQuery(payload);
    yield call(() => api.post(`admin/users/send_sms`, query, {
      user_ids: ((!Object.keys(query).length) ? (payload.is_select_all ? null : payload.user_ids) : [])
    }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Смс сообщения были отправлены');
  } catch (error) {
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* getLastSmsSendDate({ payload }) {
  try {
    yield put(actions.setUsers({ lastSmsSendDate: null }));
    const data = yield call(() => api.get(`admin/users/last_sms_date`, {}));
    yield put(actions.setUsers({ lastSmsSendDate: data.date }));
  } catch (error) {
    yield put(actions.setUsers({ error: error, lastSmsSendDate: null }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS, loadUsers),
    takeEvery(actions.SEARCH_USERS, searchUsers),
    takeEvery(actions.EXPORT_USERS, exportUsers),
    takeEvery(actions.SEND_PUSH_TO_USERS, sendPushToUsers),
    takeEvery(actions.LAST_SMS_SEND_DATE, getLastSmsSendDate),
    takeEvery(actions.SEND_SMS_TO_USERS, sendSmsToUsers),
  ]);
}
