import update from 'immutability-helper';
import actions from './actions';

const initState = {
  users: [],
  isSuccess: false,
  lastSmsSendDate: null,
  pageSize: null,
  page: null,
  totalElements: null,
  isUsersFetching: false
};

export default function users(state = initState, action) {
  switch (action.type) {
    case actions.SET_USERS:
      return update(state, {$merge: action.payload});

    case actions.SEARCH_USERS:
      return update(state, {$merge: action.payload});

    case actions.EXPORT_USERS:
      return update(state, {$merge: action.payload});

    case actions.SEND_PUSH_TO_USERS:
      return update(state, {$merge: action.payload});

    case actions.SEND_SMS_TO_USERS:
      return update(state, {$merge: action.payload});

    case actions.LAST_SMS_SEND_DATE:
      return update(state, {$merge: action.payload});

    default:
      return state;
  }
}
