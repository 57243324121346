const actions = {

  // NEWS

  LOAD_NEWS: 'LOAD_NEWS',
  LOAD_NEWS_DETAIL: 'LOAD_NEWS_DETAIL',
  SET_NEWS: 'SET_NEWS',
  ADD_NEWS: 'ADD_NEWS',
  EDIT_NEWS: 'EDIT_NEWS',
  DELETE_NEWS: 'DELETE_NEWS',

  // News Actions

  fetchNews: (params = {}) => ({ type: actions.LOAD_NEWS, payload: params }),
  fetchNewsDetail: (params = {}) => ({ type: actions.LOAD_NEWS_DETAIL, payload: params }),
  deleteNews: (params = {}) => ({ type: actions.DELETE_NEWS, payload: params }),
  addNews: (params = {}) => ({ type: actions.ADD_NEWS, payload: params }),
  editNews: (params = {}) => ({ type: actions.EDIT_NEWS, payload: params }),
  setNews: payload => ({
    type: actions.SET_NEWS,
    payload,
  }),

  CLEAR_DATA: 'CLEAR_DATA',

  clearData: () => ({
    type: actions.CLEAR_DATA,
  }),

};
export default actions;
