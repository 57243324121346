import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/utility/notification';
import {setRequestParams} from "@iso/lib/helpers/utility";
import {redirectTo} from "../library/helpers/utility";


const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('id_token')
});

const METHODS_WITH_BODY = ['POST', 'PUT', 'PATCH', 'DELETE'];

async function base(method, url, queryParams={}, data = {}, onwHeader = null, baseUrl=null, fetchAll = false) {
  const headers = (onwHeader) ? {...onwHeader} : {...customHeader()};

  let options = {
    method,
    headers,
  };
  if (METHODS_WITH_BODY.includes(method)) {
    options.body = JSON.stringify(data);
  }
  return await fetch(`${baseUrl || siteConfig.apiUrl}${url}${setRequestParams(queryParams)}`, options)
    .then(response => {
      if (response.ok) {
        if (headers['Content-Type'] !== 'application/json' ) {
          return response.blob();
        }
        return response.json();
      } else {
        throw response;
      }
    })
    .then(resp => {
      return fetchAll ? resp : (resp.data ? resp.data : resp)
    })
    .catch(error => {
      const CLIENT_ERROR_MESSAGE = 'Ошибка выполнения запроса';
      const SERVER_ERROR_MESSAGE = 'Проблема соединения с сервером';
      if (error.status === 403) {
        localStorage.removeItem('id_token');
        redirectTo('/signin')
      } else {
        if (error && (500 <= error.status && error.status < 600)) {
          if (error.status === 500) {
            try {
              return error.text()
                .then((errorText) => {
                  let error = JSON.parse(errorText);
                  throw {...error.error, code: 500};
                })
            } catch (e) {}
          }
          throw { message: SERVER_ERROR_MESSAGE, code: 500 };
        } else {
          try {
            return error.text()
              .then((errorText) => {
                if (errorText) {
                  let error = JSON.parse(errorText);
                  let errObj = error?.error;
                  let errCode = errObj ? {'code': errObj?.code || 400, 'message': errObj?.message || CLIENT_ERROR_MESSAGE}
                    : {'code': error?.code || 400, 'message': error?.message || CLIENT_ERROR_MESSAGE};
                  throw {...errCode};
                }
              })
          } catch (e) {
            throw { message: CLIENT_ERROR_MESSAGE, code: 400 };
          }
        }
      }
    });
}

export default base;
