import update from 'immutability-helper';
import actions from './actions';

const initState = {
  polls: [],
  isPollsFetching: false,

  poll: {},
  isPollFetching: false,
  isPollAdding: false,
  isPollUpdating: false,
  isPollDeleting: false,
  isPollDeleteSuccess: false,
};

export default function polls(state = initState, action) {
  switch (action.type) {
    case actions.SET_POLLS:
      return update(state, {$merge: action.payload});

    case actions.SEARCH_POLLS:
      return update(state, {$merge: action.payload});

    case actions.SET_POLL:
      return update(state, {$merge: action.payload});

    case actions.CLEAR_DATA:
      return update(state, {$set: {
        ...state,
          poll: {}
      }});

    default:
      return state;
  }
}
