import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '../../api'
import { mock } from '@iso/containers/mock';
import { push } from 'react-router-redux';
import {openNotificationWithIcon} from "../../components/utility/utils";
import {NOTIFICATION_STATUSES} from "../../config/common";


function* loadDiscounts({ payload }) {
  try {
    yield put(actions.setDiscounts({ isDiscountsFetching: true, redirect: false }));
    const data = yield call(() => api.get(`admin/discount_services`));
    yield put(actions.setDiscounts(
      {
        discounts: data,
        isDiscountsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setDiscounts({ error: error, isDiscountsFetching: false, redirect: false }));
  }
}

function* loadDiscount({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setDiscounts({ isDiscountsFetching: true, redirect: false }));
    const data = yield call(() => api.get(`admin/discount_services/${params.id}`));
    yield put(actions.setDiscounts(
      {
        discount: data,
        isDiscountsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setDiscounts({ error: error, isDiscountsFetching: false }));
  }
}

function* addDiscount({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setDiscounts({ isDiscountsFetching: true, redirect: false }));
    yield call(() => api.post(`admin/discount_services`, {}, params));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Акция создана');
    yield put(actions.setDiscounts(
      {
        redirect: true,
        isDiscountsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setDiscounts({ error: error, isDiscountsFetching: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* editDiscount({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setDiscounts({ isDiscountsFetching: true, redirect: false }));
    yield call(() => api.put(`admin/discount_services/${params.id}`, {}, params));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Акция изменена');
    yield put(actions.setDiscounts(
      {
        redirect: true,
        isDiscountsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setDiscounts({ error: error, isDiscountsFetching: false }));
    console.log(error);
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

function* deleteDiscount({ payload }) {
  try {
    const params = { ...payload };
    yield put(actions.setDiscounts({ isDiscountsFetching: true, redirect: false }));
    yield call(() => api.delete(`admin/discount_services/${params.id}`, {}));
    openNotificationWithIcon(NOTIFICATION_STATUSES.success, 'Успешно', 'Акция удалена');
    yield put(actions.setDiscounts(
      {
        redirect: true,
        isDiscountsFetching: false
      }
    ));
  } catch (error) {
    yield put(actions.setDiscounts({ error: error, isDiscountsFetching: false }));
    openNotificationWithIcon(NOTIFICATION_STATUSES.error, 'Ошибка', error.message);
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(actions.LOAD_DISCOUNTS, loadDiscounts),
    takeEvery(actions.LOAD_DISCOUNT, loadDiscount),
    takeEvery(actions.ADD_DISCOUNT, addDiscount),
    takeEvery(actions.EDIT_DISCOUNT, editDiscount),
    takeEvery(actions.DELETE_DISCOUNT, deleteDiscount),
  ]);
}
